<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Empresas</b>

        Dialog.dialogApagar(header='Remover Empresa' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a unidade <b>{{ dialogApagar_data.nm_unidade }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-8
                    label.form-label Empresa:
                    .p-inputgroup
                        InputText(placeholder='Empresa'
                            @keyup.enter.native='applyFilters()'
                            @input='checkEmpty_nm_fantasia()'
                            v-model='filters.nm_fantasia'
                            v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Tipo Empresa:
                    Dropdown(
                        v-model='filters.cd_tipo_empresa'
                        :options='options.tiposEmpresa'
                        :disabled='waiting'
                        @change='applyFilters()'
                        optionLabel='text'
                        optionValue='value'
                        placeholder='Selecione')

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/empresas/salvar/0/')")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
                            .ta-left
                                p <b>Nome Fantasia:</b> {{ props.data.nm_fantasia }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Empresas')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list")

                    Column(headerStyle='width: 30%;' field='nm_fantasia' header='Nome Fantasia')
                    Column(headerStyle='width: 30%;' field='nm_razao_social' header='Razão social')
                    Column(headerStyle='width: 15%;' bodyClass="ta-center" field='nr_cnpj' header='CNPJ')
                    Column(headerStyle='width: 15%;' bodyClass="ta-center" field='tipo_empresa' header='Tipo')
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/empresas/salvar/${ props.data.id }/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-if="!props.data.id_externo"
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar = true; dialogApagar_data = props.data"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-else
                                    v-tooltip.top="'Impossível apagar empresa proveniente de integração.'"
                                    icon="jam jam-minus-circle"
                                    disabled
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Dropdown from 'primevue/dropdown'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'

    import { Empresa } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'empresas') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () {
            // this.applyFilters()
            this.getTiposEmpresa()
        },
        components: { ProgressBar, DataView, Dropdown, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
					nm_fantasia: '',
                    cd_tipo_empresa: null
				},
                options: { tiposEmpresa: [] },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            checkEmpty_nm_fantasia () {
                if (! this.filters.nm_fantasia) this.applyFilters()
            },
            getTiposEmpresa() {
                this.waitingTipoEmpresa = true
                Empresa.getTiposEmpresa().then(response => {
                    this.waitingTipoEmpresa = false
                    if (response.status === 200) {
                        this.options.tiposEmpresa = response.data.map(tipo => {
                            return { value: tipo.id, text: tipo.ds_tipo_empresa }
                        })
                        this.options.tiposEmpresa.unshift({ value: null, text: "TODOS"})
                    }
                })
            },
            getList (params) {
                this.waiting = true
                return Empresa.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        response.data.results.forEach(empresa => {
                            if (empresa.nr_cnpj) empresa.nr_cnpj_f = empresa.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                        })
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                Empresa.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Empresa removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>
